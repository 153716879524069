import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import { Link } from 'react-router-dom';
const Navbar = () => {
  // State to manage the visibility of the "More" dropdown
  const [moreOpen, setMoreOpen] = useState(false);
  const { item, setItem } = useContext(CatContext);

  // Toggle the dropdown on click
  const toggleMoreDropdown = () => {
  };

  return (
    <div>
      {/* Call Button for Small Screens */}
      <div className="bg-blue text-center d-block d-lg-none py-1">
        <a className="text-white text-decoration-none" href="tel:0204 618 9248">
          <i className="fa-solid fa-phone" /> 0204 618 9248
        </a>
      </div>

      {/* Main Navbar */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary py-2">
        <div className="container">
          {/* Logo */}
          <Link className="navbar-brand" to="/">
            <img
              src="/assets/header-logo.png"
              width={190}
              alt="Logo"
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                e.target.src = "../assets/header-logo.png"; // Set fallback path
              }}
            />

          </Link>

          {/* Toggle Button for Small Screens */}
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Collapsible Navbar Items */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent" >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0" >
              {/* Home Link */}
              <li className="nav-item">
                <Link className="nav-link fw-semibold text-blue text-uppercase fnt-14" to="/">
                  Home
                </Link>
              </li>

              {/* Services Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle fw-semibold text-blue text-uppercase fnt-14"
                  to="/destination"
                  id="navbarDropdown"
                  role="button"
                  // data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Destinations
                </Link>
                <ul
                  className="dropdown-menu dropdown-mobile"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link onClick={() => setItem('maldives')} className="dropdown-item" to="/destination/maldives">
                      Maldives
                    </Link>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>Mexico</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('cancun')} className="dropdown-item" to="/destination/mexico/canun">Cancun</Link></li>
                    </ul>
                  </li>
                  {/* Nested Dropdown */}
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>USA</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('miami')} className="dropdown-item" to="/destination/usa/miami">Miami</Link></li>
                      <li><Link onClick={() => setItem('lasvegas')} className="dropdown-item" to="/destination/usa/las-vegas">Las Vegas</Link></li>
                      <li><Link onClick={() => setItem('losangeles')} className="dropdown-item" to="/destination/usa/los-angeles">Los Angeles</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>Thailand</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('phuket')} className="dropdown-item" to="/destination/thailand/phuket">Phuket</Link></li>
                      <li><Link onClick={() => setItem('bangkok')} className="dropdown-item" to="/destination/thailand/bangkok">Bangkok</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>Turkey</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('antalya')} className="dropdown-item" to="/destination/turkey/antalya">Antalya</Link></li>
                      <li><Link onClick={() => setItem('istanbul')} className="dropdown-item" to="/destination/turkey/istanbul">Istanbul</Link></li>
                      <li><Link onClick={() => setItem('marmaris')} className="dropdown-item" to="/destination/turkey/marmaris">Marmaris</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>Spain</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('barcelona')} className="dropdown-item" to="/destination/spain/barcelona">Barcelona</Link></li>
                      <li><Link onClick={() => setItem('balearicisland')} className="dropdown-item" to="/destination/spain/balearic-island">Balearic Island</Link></li>
                      <li><Link onClick={() => setItem('madrid')} className="dropdown-item" to="/destination/spain/madrid">Madrid</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link onClick={() => setItem('singapore')} className="dropdown-item" to="/destination/singapore">
                      Singapore
                    </Link>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>UAE</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('abudhabi')} className="dropdown-item" to="/destination/uae/abu-dhabi">Abu Dhabi</Link></li>
                      <li><Link onClick={() => setItem('dubai')} className="dropdown-item" to="/destination/uae/dubai">Dubai</Link></li>
                      <li><Link onClick={() => setItem('sharja')} className="dropdown-item" to="/destination/uae/sharja">Sharja</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>Dominican Republic</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('puntacana')} className="dropdown-item" to="/destination/dominican/punta-cana">Punta Cana</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" onClick={toggleMoreDropdown}>Indonesia</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('bali')} className="dropdown-item" to="/destination/indonesia/bali">Bali</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link onClick={() => setItem('srilanka')} className="dropdown-item" to="/destination/srilanka">
                      Sri Lanka
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => setItem('mauritius')} className="dropdown-item" to="/destination/mauritius">
                      Mauritius
                    </Link>
                  </li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle" to="/" onClick={toggleMoreDropdown}>Caribbean</a>
                    <ul className={`dropdown-menu ${moreOpen ? 'show' : ''}`}>
                      <li><Link onClick={() => setItem('barbados')} className="dropdown-item" to="/destination/caribbean/barbados">Barbados</Link></li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link fw-semibold text-blue text-uppercase fnt-14" to="/multi-destination-packages">
                  Multi Destinations
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link fw-semibold text-blue text-uppercase fnt-14" to="/inquire">
                  Enquire
                </Link>
              </li>
            </ul>

            {/* Call Button */}
            <form className="d-flex align-items-center">
              <a
                className="btn btn-blue text-white ms-4 d-none d-lg-block"
                href="tel:0204 618 9248"
              >
                <i className="fa-solid fa-phone" /> 0204 618 9248
              </a>
              <a
                className="btn btn-blue text-white d-block d-lg-none"
                href="tel:0204 618 9248"
              >
                <i className="fa-solid fa-phone" /> 0204 618 9248
              </a>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
