import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Form from '../Components/Form'
import { Link } from 'react-router-dom'

const Home = () => {
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [loading, setLoading] = useState(true)
  const { res, setRes } = useContext(CatContext);
  const { item, setItem } = useContext(CatContext);
  const [destination, setDestination] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
  
    if (!destination) {
      alert('Please enter a destination');
      return;
    }
  
    try {
      const response = await fetch(`http://localhost:5050/search?destination=${destination}`);
      const data = await response.json();
      setRes(data) 
    } catch (error) {
      console.log(destination)
      console.error('An error occurred:', error);
    }
  };

let fetchdata = async()=>{
  try {
    let res = await fetch('http://localhost:5050/destination/dubai')
    res = await res.json()
    setData(res) 
    setLoading(false) 
  } catch (error) {
    console.log(error)
  }  
}
let fetchdata2 = async()=>{
  try {
    let res = await fetch('http://localhost:5050/destination/singapore')
    res = await res.json()
    setData2(res)  
  } catch (error) {
    console.log(error)
  }  
}
let fetchdata3 = async()=>{
  try {
    let res = await fetch('http://localhost:5050/destination/phuket')
    res = await res.json()
    setData3(res)  
  } catch (error) {
    console.log(error)
  }  
}

useEffect(()=>{
  fetchdata()
  fetchdata2()
  fetchdata3()
},[])




  return (
    <>
    {
      loading?(
        <div className="d-flex justify-content-center align-items-center" style={{height:"600px"}}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      ):(
        <div>
      <Navbar />
      <div>
        <div className="image d-flex align-items-center justify-content-center">
          <div className='container py-3 rounded-4 background-blur'>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 my-1">
                <div className='px-3 '>
                  <label className='text-white mb-1'>Departure From</label>
                  <input type="text" className='w-100 px-3 py-2 rounded-1 border-none outline-none' placeholder='Departure From' />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 my-1">
                <div className='px-3'>
                  <label className='text-white mb-1'>Destination</label>
                  <input type="text" className='w-100 px-3 py-2 rounded-1 border-none outline-none' placeholder='Destination'
                value={destination}
                onChange={(e) => setDestination(e.target.value)} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 my-1">
                <div className='px-3'>
                  <label className='text-white mb-1'>When</label>
                  <input type="date" className='w-100 px-3 py-2 text-secondary rounded-1 border-none outline-none' placeholder='Date' />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center">
                <div className='px-3 mt-1'>
                  <Link to="/holidays" onClick={handleSearch} className="btn btn-danger mt-4 px-5 py-2"> <i className='fa-solid fa-search' /> <Link to="/holidays" className='me-1 text-decoration-none text-white'> Find Holidays </Link></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form/>
        <div>
          <div className="container py-5">
          <div className="h2 text-center fw-semibold text-blue">Featured Offers</div>
            <div className="row">
            <div>
              <div className="h4 text-blue text-center mt-4">Dubai Packages</div>
            </div>
           <div className="row">
            {
              data?.slice(0,3).map((x, i)=>{
                return(
                  <div className="col-lg-4 col-md-6 col-12 my-3 ">
                <div className='position-relative'>
                  <div className='scale-hover'>
                    <img src={`./new/${x.image[0]}`} className='w-100 ' style={{height:"240px"}} alt="" />
                  </div>
                  <div
                    className='bg-light-blue position-absolute w-100'
                    style={{bottom: 0, left: 0 }}>
                      <div className="row pt-2">
                        <div className="col ps-4">
                          <div className="h6">{x.title.slice(0,40)}...</div>
                          <div className="fnt-12">{x.city}</div>
                          <div className="h6 fw-semibold">SAVE {x.off}%</div>
                        </div>
                        <div className="col text-center">
                          <div className="fnt-12">{x.packageDays} hb from</div>
                          <div className="h5">£{x.price}/<span className='fnt-12 '>pp</span></div>
                          <button className='btn btn-info text-white py-0 fnt-12 rounded-1'>View More</button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
                )
              })
            }
           </div>
           <div className='text-center'>
           <Link to='/destination/uae/dubai' onClick={()=> setItem('dubai')} className="btn btn-blue mx-auto text-white">View All Packages</Link>
           </div>
            </div>
            <div className="row">
            <div>
              <div className="h4 text-blue text-center mt-4">Singapore Packages</div>
            </div>
           <div className="row">
            {
              data2?.slice(0,3).map((x, i)=>{
                return(
                  <div className="col-lg-4 col-md-6 col-12 my-3 ">
                <div className='position-relative'>
                  <div className='scale-hover'>
                    <img src={`./new/${x.image[0]}`} className='w-100 ' style={{height:"240px"}} alt="" />
                  </div>
                  <div
                    className='bg-light-blue position-absolute w-100'
                    style={{bottom: 0, left: 0 }}>
                      <div className="row pt-2">
                        <div className="col ps-4">
                          <div className="h6">{x.title.slice(0,40)}...</div>
                          <div className="fnt-12">{x.city}</div>
                          <div className="h6 fw-semibold">SAVE {x.off}%</div>
                        </div>
                        <div className="col text-center">
                          <div className="fnt-12">{x.packageDays} hb from</div>
                          <div className="h5">£{x.price}/<span className='fnt-12 '>pp</span></div>
                          <button className='btn btn-info text-white py-0 fnt-12 rounded-1'>View More</button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
                )
              })
            }
           </div>
           <div className='text-center'>
           <Link to='/destination/singapore' onClick={()=> setItem('singapore')} className="btn btn-blue mx-auto text-white">View All Packages</Link>
           </div>
            </div>
            <div className="row">
            <div>
              <div className="h4 text-blue text-center mt-4">Phuket Packages</div>
            </div>
           <div className="row">
            {
              data3?.slice(0,3).map((x, i)=>{
                return(
                  <div className="col-lg-4 col-md-6 col-12 my-3 ">
                <div className='position-relative'>
                  <div className='scale-hover'>
                    <img src={`./new/${x.image[0]}`} className='w-100 ' style={{height:"240px"}} alt="" />
                  </div>
                  <div
                    className='bg-light-blue position-absolute w-100'
                    style={{bottom: 0, left: 0 }}>
                      <div className="row pt-2">
                        <div className="col ps-4">
                          <div className="h6">{x.title.slice(0,40)}...</div>
                          <div className="fnt-12">{x.city}</div>
                          <div className="h6 fw-semibold">SAVE {x.off}%</div>
                        </div>
                        <div className="col text-center">
                          <div className="fnt-12">{x.packageDays} hb from</div>
                          <div className="h5">£{x.price}/<span className='fnt-12 '>pp</span></div>
                          <button className='btn btn-info text-white py-0 fnt-12 rounded-1'>View More</button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
                )
              })
            }
           </div>
           <div className='text-center'>
           <Link to='/destination/thailand/phuket' onClick={()=> setItem('phuket')} className="btn btn-blue mx-auto text-white">View All Packages</Link>
           </div>
            </div>
          </div>
        </div>
        <div className="testimonial py-5">
          <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2500">
            <div className="carousel-inner ">
              <div className="carousel-item active">
                <div className='w-50 mx-auto d-none d-md-block'>
                  <div className="h5 text-center pb-3 text-blue">Kuredu Island Resort Maldives</div>
                  <div>
                    <p className='text-center'>I thought that i would take some time to drop you a brief note....</p>
                    <p className='text-center'>It's been almost three weeks since our return from Kerudu island Maldives and we just wanted to thank you for putting together such a wonderful holiday. Everything was much higher than expectation. We had such a wonderful time. The Sangu Water Villas were absolutely amazing. The food choice in the Sangu restaurant was excellent, something to suit everyone. Service off the waiters and bar staff was excellent. Nothing was too much trouble. We didn't use any of the other restaurants, didn't feel the need to. Well satisfied with everything.</p>
                  </div>
                </div>
                <div className='px-3 d-block d-md-none'>
                  <div className="h5 text-center pb-3 text-blue">Kuredu Island Resort Maldives</div>
                  <div>
                    <p className=''>I thought that i would take some time to drop you a brief note....</p>
                    <p className=''>It's been almost three weeks since our return from Kerudu island Maldives and we just wanted to thank you for putting together such a wonderful holiday. Everything was much higher than expectation. We had such a wonderful time. The Sangu Water Villas were absolutely amazing. The food choice in the Sangu restaurant was excellent, something to suit everyone. Service off the waiters and bar staff was excellent. Nothing was too much trouble. We didn't use any of the other restaurants, didn't feel the need to. Well satisfied with everything.</p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className='w-50 mx-auto d-none d-md-block'>
                  <div className="h5 text-center pb-3 text-blue">Sri Lanka Holidays</div>
                  <div>
                    <p className="text-center">Dear Charles, We came back from our Sri Lanka holidays and we could not be more unanimous: overall the best holidays ever! Culture and relaxation were perfectly balanced. Sri Lanka is so rich in every single nature and culture aspects that it is very hard to say which is more worth experiencing/seeing. Everything from the best landscapes, rich History, small villages and nice villagers to snorkel, rafting, safari, temples, wild animals like monkeys or elephants crossing the roads, everything was beyond appreciated and tasted to the last minute!</p>
                  </div>
                </div>
                <div className='px-3 d-block d-md-none'>
                  <div className="h5 text-center pb-3 text-blue">Sri Lanka Holidays</div>
                  <div>
                    <p className="text-center">Dear Charles, We came back from our Sri Lanka holidays and we could not be more unanimous: overall the best holidays ever! Culture and relaxation were perfectly balanced. Sri Lanka is so rich in every single nature and culture aspects that it is very hard to say which is more worth experiencing/seeing. Everything from the best landscapes, rich History, small villages and nice villagers to snorkel, rafting, safari, temples, wild animals like monkeys or elephants crossing the roads, everything was beyond appreciated and tasted to the last minute!</p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className='w-50 mx-auto d-none d-md-block'>
                  <div className="h5 text-center pb-3 text-blue">Kuredu Island Resort Maldives</div>
                  <div>
                    <p className="ps-3">Hi Liam, <br />2 Words - Absolutely Fabulous <br />3 more - Huge Thank You</p>
                    <p className="text-center">I will definitely book with you again. Everything went like clockwork and the resort was better than we imagined. Thank you for handling the reservation process so professionally and making it easy for me (unlike my requirements and the issues I put your way).</p>
                  </div>
                </div>
                <div className='px-3 d-block d-md-none'>
                  <div className="h5 text-center pb-3 text-blue">Kuredu Island Resort Maldives</div>
                  <div>
                    <p className="ps-3">Hi Liam, <br />2 Words - Absolutely Fabulous <br />3 more - Huge Thank You</p>
                    <p className="text-center">I will definitely book with you again. Everything went like clockwork and the resort was better than we imagined. Thank you for handling the reservation process so professionally and making it easy for me (unlike my requirements and the issues I put your way).</p>
                  </div>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden ">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>

        </div>
        <div className='my-5'>
          <div className='text-center mb-5'>
            <div className="h5 text-blue">A few of our partners</div>
            <p className='mx-3'>Listed below is a few of our partners we work with to ensure your holiday is perfect</p>
          </div>
          <div className="container-fluid">
            <div className="row px-3 text-center">
              <div className="col"><img className='hover-effect py-3' src="./assets/emirates.png" alt="" /></div>
              <div className="col"><img className='hover-effect py-3' src="./assets/etihad.png" alt="" /></div>
              <div className="col"><img className='hover-effect py-3' src="./assets/british.png" alt="" /></div>
              <div className="col"><img className='hover-effect py-3' src="./assets/virgin.png" alt="" /></div>
              <div className="col"><img className='hover-effect py-3' src="./assets/oman.png" alt="" /></div>
              <div className="col"><img className='hover-effect py-3' src="./assets/qata.png" alt="" /></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
      )
    }
    </>
  )
}

export default Home